const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://2uvdfe0ol1.execute-api.ap-southeast-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://2v9edhpk45.execute-api.ap-southeast-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.staging.newcrest.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'Australia/Sydney'
  },
  reactApp: {
    VERSION: 'targets-1.7.0',
    HOSTNAME: 'https://targets.staging.newcrest.forwoodsafety.com'
  }
};

export default config;